import { BillingHistoryItem } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/billing_pb';
import {
  FullProfileInfo,
  ProfileGender,
  ProfileLookingFor,
  ProfileSexualOrientation,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/profile_pb';
import {
  GetAccountResponse,
  GetExtraInfoResponse,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_api_pb';
import {
  LogItemWithFields,
  LogItemWithPhotos,
  SearchUser,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/users/v1/users_pb';
import { AccountStatus } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';
import {
  Subscription,
  SubscriptionStateActive,
  SubscriptionStateInactive,
  SubscriptionStatePending,
  SubscriptionStateSuspended,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/gateway/web/payment/v2/subscription_pb';

import { MemberStateMember } from '@/pages/member/store/member-state';

export type UsersStateUserTag = 'fake' | 'test';

export type UsersStateUser = Omit<SearchUser.AsObject, 'createdTime' | 'tagsList'> & {
  createdTimeMs: number;
  tagsList: UsersStateUserTag[];
};

export type UsersStateProfile = Required<FullProfileInfo.AsObject>;

export type UsersStateAccount = Required<GetAccountResponse.AsObject>;

export type UsersStateExtraInfo = Required<GetExtraInfoResponse.AsObject>;

export type UsersStateSubscriptionState = (
  | SubscriptionStateActive.AsObject
  | SubscriptionStatePending.AsObject
  | SubscriptionStateInactive.AsObject
  | SubscriptionStateSuspended.AsObject
) & { type: 'active' | 'pending' | 'suspended' | 'inactive' };

export type UsersStateSubscription = Omit<
  Subscription.AsObject,
  'active' | 'pending' | 'suspended' | 'inactive'
> & { state: UsersStateSubscriptionState };

export type UsersStateLogItemPhotos = LogItemWithPhotos.AsObject;

export type UsersStateLogItemFields = Array<LogItemWithFields.Field.AsObject>;

export type UsersStateLogItemAction =
  | 'backoffice_commands_v1_ModeratePhotoCommand'
  | 'gdx_backoffice_web_newprofiles_v1_NewProfilesAPI/MakeDecision'
  | 'gdx_backoffice_web_users_v1_UsersAPI/DeletePhoto'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SendEmailRecovery'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SetAccount'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SetAccountStatus'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SetBilling'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SetProfile'
  | 'gdx_backoffice_web_users_v1_UsersAPI/SetSettings'
  | 'gdx_backoffice_web_users_v1_UsersAPI/UnsubscribePremium'
  | 'gdx_backoffice_web_users_v1_UsersAPI/UploadPhoto'
  | 'gdx_backoffice_web_users_v1_UsersAPI/UpdateAvatar'
  | 'gdx_backoffice_web_users_v1_UsersAPI/GetOneTimeHash';

export type UsersStateLogItem = {
  action: UsersStateLogItemAction;
  reasonContent: string;
  author: MemberStateMember;
  createdTimeMs: number;
  photos?: UsersStateLogItemPhotos;
  fields?: UsersStateLogItemFields;
};

export type UsersStateSearchCriteria = {
  filter?: string;
  hasTrial?: boolean;
  hasPremium?: boolean;
  gender?: ProfileGender;
  hasPhoto?: boolean;
  isRegularUser?: boolean;
  registrationDateRange?: {
    fromTimeMs?: number;
    toTimeMs?: number;
  };
  lookingFor?: ProfileLookingFor;
  matchGender?: ProfileGender;
  sexualOrientation?: ProfileSexualOrientation;
  status?: AccountStatus;
  tags?: string[];
};

export type UsersStateBilling = {
  creditsCount: number;
  isPremiumActive: boolean;
  premiumEndsTimeMs: number;
};

export type UsersStateBillingHistoryItem = {
  id: string;
  type: BillingHistoryItem.Type;
  title: string;
  reasonContent: string;
  authorName: string;
  changeCreditsCount: number;
  resultCreditsCount: number;
  createdTimeMs: number;
};

export type UsersState = {
  users: UsersStateUser[];
  searchCriteria: UsersStateSearchCriteria;
  searchUsersAfter: string;
  billingHistory: UsersStateBillingHistoryItem[];
  searchBillingHistoryAfter: string;
  billing: UsersStateBilling;
  profile?: UsersStateProfile;
  account?: UsersStateAccount;
  extraInfo?: UsersStateExtraInfo;
  subscription?: UsersStateSubscription;
  logs: UsersStateLogItem[];
  searchLogAfter: string;
};

export const state: UsersState = {
  users: [],
  searchCriteria: {},
  searchUsersAfter: '',
  billingHistory: [],
  searchBillingHistoryAfter: '',
  billing: {
    creditsCount: 0,
    premiumEndsTimeMs: 0,
    isPremiumActive: false,
  },
  profile: undefined,
  account: undefined,
  extraInfo: undefined,
  subscription: undefined,
  logs: [],
  searchLogAfter: '',
};
