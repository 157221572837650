import { createRouter, createWebHistory, RouteLocation, RouteRecordRaw } from 'vue-router';

import { runSmartLook } from '@/helpers/smartlook/smartlook';
import { getAndSaveMemberInfo } from '@/pages/member/store/helpers/member-helper';
import { setSentryUser } from '@/services/sentry/sentry';
import { connectToWebsocketServer } from '@/services/websocket';
import { store } from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'root',
    path: '/',
    redirect: '/member',
  },
  {
    path: '/member',
    component: () => import('@/pages/member/Member.vue'),
    children: [
      {
        name: 'memberAuth',
        path: '',
        component: () => import('@/pages/member/components/member-signin/MemberSignin.vue'),
        meta: {
          isGuest: true,
        },
      },
    ],
  },
  {
    path: '/admin',
    component: () => import('@/pages/admin/Admin.vue'),
    children: [
      {
        name: 'adminMember',
        path: '',
        component: () => import('@/pages/admin/components/admin-member/AdminMember.vue'),
      },
      {
        name: 'adminSettings',
        path: 'settings',
        component: () => import('@/pages/admin/components/admin-settings/AdminSettings.vue'),
      },
    ],
  },
  {
    path: '/home',
    component: () => import('@/pages/home/Home.vue'),
    children: [
      {
        name: 'home',
        path: '',
        component: () => import('@/pages/home/components/home-grid/HomeGrid.vue'),
      },
      {
        name: 'homeMario',
        path: 'mario',
        component: () => import('@/pages/home/components/mario/Mario.vue'),
      },
    ],
  },
  {
    path: '/new-profiles',
    component: () => import('@/pages/new-profiles/NewProfiles.vue'),
    children: [
      {
        name: 'newProfilesPhotos',
        path: 'photos',
        component: () =>
          import(
            '@/pages/new-profiles/components/new-profiles-photo-grid/NewProfilesPhotoGrid.vue'
          ),
      },
      {
        name: 'newProfilesAiDeclinedPhotos',
        path: 'ai-declined',
        component: () =>
          import(
            '@/pages/new-profiles/components/new-profiles-ai-declined-photo-grid/NewProfilesAiDeclinedPhotoGrid.vue'
          ),
      },
    ],
  },
  {
    path: '/users',
    component: () => import('@/pages/users/Users.vue'),
    children: [
      {
        name: 'users',
        path: '',
        component: () => import('@/pages/users/components/users-list/UsersList.vue'),
      },
      {
        name: 'usersProfile',
        path: ':userId/profile',
        component: () => import('@/pages/users/components/users-dashboard/UsersDashboard.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            // eslint-disable-next-line no-use-before-define
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersAccount',
        path: ':userId/account',
        component: () => import('@/pages/users/components/users-account/UsersAccount.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            // eslint-disable-next-line no-use-before-define
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersSplits',
        path: ':userId/splits',
        component: () => import('@/pages/users/components/users-splits/UsersSplits.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersExtra',
        path: ':userId/extra',
        component: () => import('@/pages/users/components/users-extra/UsersExtra.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            // eslint-disable-next-line no-use-before-define
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersLog',
        path: ':userId/log',
        component: () => import('@/pages/users/components/users-log/UsersLog.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersReportAbuse',
        path: ':userId/report-abuse',
        component: () => import('@/pages/users/components/users-report-abuse/UsersReportAbuse.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersBenefits',
        path: ':userId/benefits',
        component: () => import('@/pages/users/components/users-benefits/UsersBenefits.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersSubscription',
        path: 'subscription/:userId',
        component: () =>
          import('@/pages/users/components/users-subscription/UsersSubscription.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersPhotoBank',
        path: 'photo-bank/:userId',
        component: () => import('@/pages/users/components/users-photo-bank/UsersPhotoBank.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersAntifraud',
        path: 'antifraud/:userId',
        component: () => import('@/pages/users/components/users-antifraud/UsersAntifraud.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
      {
        name: 'usersTemp',
        path: 'temp/:userId',
        component: () => import('@/pages/users/components/users-temp-edit/UsersTempEdit.vue'),
        props: (route) => ({
          userId: route.params.userId,
          back: () => {
            pushRoute('users');
          },
        }),
      },
    ],
  },
  {
    path: '/chat',
    component: () => import('@/pages/chat/Chat.vue'),
    children: [
      {
        name: 'chatUnanswered',
        path: '/chat/unanswered',
        component: () => import('@/pages/chat/components/unanswered/Unanswered.vue'),
      },
      {
        name: 'chatUnansweredId',
        path: '/chat/unanswered/:dialogId/:chatterId',
        component: () => import('@/pages/chat/components/unanswered/Unanswered.vue'),
        props: true,
      },
      {
        name: 'chatFullScreen',
        path: '/chat/fullscreen',
        component: () => import('@/pages/chat/components/full-screen/FullScreen.vue'),
      },
      {
        name: 'chatFullScreenId',
        path: '/chat/fullscreen/:dialogId/:chatterId',
        component: () => import('@/pages/chat/components/full-screen/FullScreen.vue'),
        props: true,
      },
      {
        name: 'chatInspect',
        path: '/chat/inspect/:userId?/:dialogId?/:messageId?',
        component: () => import('@/pages/chat/components/chat-inspect/ChatInspect.vue'),
        props: true,
      },
      {
        name: 'chatSessionManagement',
        path: '/chat/session-management',
        component: () => import('@/pages/chat/components/session-management/SessionManagement.vue'),
      },
      {
        name: 'chatBotson',
        path: '/chat/botson',
        component: () => import('@/pages/chat/components/chat-botson/ChatBotson.vue'),
      },
      {
        name: 'chatAssistant',
        path: '/chat/assistant',
        component: () => import('@/pages/chat/components/chat-assistant/ChatAssistant.vue'),
      },
      {
        name: 'chatBehavior',
        path: '/chat/behavior',
        component: () => import('@/pages/chat/components/chat-behavior/ChatBehavior.vue'),
      },
      {
        name: 'chatDispatcher',
        path: '/chat/dispatcher',
        component: () => import('@/pages/chat/components/chat-dispatcher/ChatDispatcher.vue'),
      },
    ],
  },

  {
    path: '/keywords',
    component: () => import('@/pages/keywords/Keywords.vue'),
    children: [
      {
        name: 'keywords',
        path: '',
        component: () =>
          import(
            '@/pages/keywords/components/keywords-matched-profiles/KeywordsMatchedProfiles.vue'
          ),
      },
      {
        name: 'keywordsAllProfiles',
        path: 'all-profiles',
        component: () =>
          import('@/pages/keywords/components/keywords-all-profiles/KeywordsAllProfiles.vue'),
      },
      {
        name: 'keywordsList',
        path: 'list',
        component: () => import('@/pages/keywords/components/keywords-list/KeywordsList.vue'),
      },
    ],
  },
  {
    path: '/report-abuse',
    component: () => import('@/pages/report-abuse/ReportAbuse.vue'),
    children: [
      {
        name: 'reportAbuse',
        path: '',
        component: () =>
          import('@/pages/report-abuse/components/report-abuse-grid/ReportAbuseGrid.vue'),
      },
    ],
  },
  {
    path: '/payments',
    component: () => import('@/pages/payments/Payments.vue'),
    children: [
      {
        name: 'paymentsIssues',
        path: '',
        component: () => import('@/pages/payments/components/payments-issues/PaymentsIssues.vue'),
      },
      {
        name: 'paymentsHistory',
        path: 'history/:orderId?',
        component: () =>
          import(
            '@/pages/payments/components/payments-order-history-list/PaymentsOrderHistoryList.vue'
          ),
        props: true,
      },
    ],
  },
  {
    name: 'UsersNotes',
    path: '/:userId/users-notes',
    component: () => import('@/pages/users/components/users-notes/UsersNotes.vue'),
    props: (route) => ({
      userId: route.params.userId,
      back: () => {
        pushRoute('users');
      },
    }),
  },
  {
    path: '/antifraud',
    component: () => import('@/pages/antifraud/Antifraud.vue'),
    children: [
      {
        name: 'antifraudCards',
        path: 'cards',
        component: () => import('@/pages/antifraud/components/antifraud-list/AntifraudList.vue'),
        props: {
          activeTab: 'cards',
        },
      },
      {
        name: 'antifraudScore',
        path: 'score',
        component: () => import('@/pages/antifraud/components/antifraud-score/AntifraudScore.vue'),
      },
      {
        name: 'antifraudModerated',
        path: 'moderated',
        component: () => import('@/pages/antifraud/components/antifraud-list/AntifraudList.vue'),
        props: {
          activeTab: 'moderated',
        },
      },
      {
        name: 'antifraudRank',
        path: 'rank',
        component: () => import('@/pages/antifraud/components/antifraud-rank/AntifraudRank.vue'),
      },
      {
        name: 'antifraudDup',
        path: 'dup',
        component: () => import('@/pages/antifraud/components/antifraud-dup/AntifraudDup.vue'),
      },
    ],
  },
  {
    path: '/marketing',
    component: () => import('@/pages/marketing/Marketing.vue'),
    children: [
      {
        name: 'marketingFacebookTokens',
        path: 'facebook/tokens',
        component: () =>
          import('@/pages/marketing/components/facebook-token-list/FacebookTokenList.vue'),
      },
      {
        name: 'marketingFacebookBid',
        path: 'facebook/bid',
        component: () => import('@/pages/marketing/components/facebook-bid/FacebookBid.vue'),
      },
    ],
  },
];

type Routes = {
  root: undefined;
  memberAuth: undefined;
  adminMember: undefined;
  adminSettings: undefined;
  home: undefined;
  homeMario: undefined;
  newProfilesPhotos: undefined;
  newProfilesAiDeclinedPhotos: undefined;
  users: undefined;
  usersProfile: { userId: string };
  usersAccount: { userId: string };
  usersSplits: { userId: string };
  usersExtra: { userId: string };
  usersLog: { userId: string };
  usersReportAbuse: { userId: string };
  usersBenefits: { userId: string };
  usersSubscription: { userId: string };
  usersPhotoBank: { userId: string };
  usersAntifraud: { userId: string };
  usersTemp: { userId: string };
  chatUnanswered: undefined;
  chatUnansweredId: { dialogId: string; chatterId: string };
  chatFullScreen: undefined;
  chatFullScreenId: { dialogId: string; chatterId: string };
  chatInspect: { userId?: string; dialogId?: string; messageId?: string };
  chatSessionManagement: undefined;
  chatBotson: undefined;
  chatAssistant: undefined;
  chatBehavior: undefined;
  chatDispatcher: undefined;
  reportAbuse: undefined;
  split: undefined;
  splits: undefined;
  keywords: undefined;
  keywordsAllProfiles: undefined;
  keywordsList: undefined;
  UsersNotes: { userId: string };
  paymentsIssues: undefined;
  paymentsHistory: { orderId: string };
  antifraudCards: undefined;
  antifraudScore: undefined;
  antifraudModerated: undefined;
  antifraudRank: undefined;
  antifraudDup: undefined;
  marketingFacebookTokens: undefined;
  marketingFacebookBid: undefined;
};

type Router<T extends Routes> = {
  <R extends keyof T>(name: R, ...params: T[R] extends undefined ? [] : [T[R]]): void;
  <R extends keyof T>(name: R, params: T[R], query: Record<string, string>): void;
};

type RouteResolverReturn = RouteLocation & {
  href: string;
};

type RouterResolver<T> = {
  <R extends keyof T>(
    name: R,
    ...params: T[R] extends undefined ? [] : [T[R]]
  ): RouteResolverReturn;
  <R extends keyof T>(name: R, params: T[R], query: Record<string, string>): RouteResolverReturn;
};

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Guests area.
  if (to.meta.isGuest) {
    next();
  } else {
    // Secure area.
    getAndSaveMemberInfo().then(() => {
      connectToWebsocketServer();
      const member = store.state.member.member;
      if (member.email !== '') {
        runSmartLook({
          userId: member.id,
          email: member.email,
        });
        setSentryUser({
          id: member.id,
          email: member.email,
          username: member.fullName,
        });
      }

      next();
    });
  }
});

export const resolveRoute: RouterResolver<Routes> = (name, params?, query?) => {
  const info = router.resolve({
    name,
    params,
    query,
  });

  const fullPath = `${window.location.origin}${info.href}`;

  return {
    ...info,
    fullPath,
  };
};

export const pushRoute: Router<Routes> = (name, params?, query?) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fullPath } = resolveRoute(name, params);
  if (router.currentRoute.value.fullPath !== fullPath) {
    router.push({ name, params, query });
  }
};

export const replaceRoute: Router<Routes> = (name, params?, query?) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fullPath } = resolveRoute(name, params);
  if (router.currentRoute.value.fullPath !== fullPath) {
    router.replace({ name, params, query });
  }
};

export function getRoute() {
  return router.currentRoute.value;
}

export { router };
