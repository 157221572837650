import { ListDialogMessagesResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_api_pb';
import {
  Dialog,
  DialogMessage,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_pb';
import { ListReportsResponse } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_abuse_api_pb';
import {
  Report,
  Resolution,
  Source,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/report_pb';

import { getDialogMessageContentFrom } from '@/pages/chat/store/helpers/chat-helper';
import { MemberStateMember } from '@/pages/member/store/member-state';
import {
  ReportAbuseStateDialog,
  ReportAbuseStateDialogMessage,
  ReportAbuseStateAbuseReport,
  ResolutionPending,
  ResolutionResolved,
  SourceDialog,
  SourceProfile,
} from '@/pages/report-abuse/store/report-abuse-state';
import {
  defaultProfilePreview,
  getProfilePreviewFrom,
} from '@/store/modules/common/helpers/common-helper';

import SourceCase = Source.SourceCase;
import ResolutionCase = Resolution.ResolutionCase;

export const REPORT_ABUSE_LIMIT = 20;

export function getReportsListFrom(response: ListReportsResponse): ReportAbuseStateAbuseReport[] {
  const reports: ReportAbuseStateAbuseReport[] = [];
  response.getReportsList().forEach((report) => {
    reports.push(getReportFrom(report));
  });

  return reports;
}

export function getReportFrom(report: Report): ReportAbuseStateAbuseReport {
  let source: SourceProfile | SourceDialog | undefined;
  const reportSource = report.getSource();
  const dialog = reportSource?.getDialog();
  const profile = reportSource?.getProfile();

  if (reportSource?.getSourceCase() === SourceCase.DIALOG) {
    source = {
      type: 'dialog',
      dialogId: dialog?.getDialogId() ?? '',
      userId: dialog?.getUserId() ?? '',
    };
  } else if (reportSource?.getSourceCase() === SourceCase.PROFILE) {
    source = {
      type: 'profile',
      userId: profile?.getUserId() ?? '',
    };
  }

  let resolution: ResolutionPending | ResolutionResolved | undefined;
  const reportResolution = report.getResolution();

  if (reportResolution?.getResolutionCase() === ResolutionCase.PENDING) {
    resolution = { type: 'pending' };
  } else if (reportResolution?.getResolutionCase() === ResolutionCase.RESOLVED) {
    const reportResolutionResolved = reportResolution?.getResolved();
    const resolvedByObj = reportResolutionResolved?.getResolvedBy();
    const resolvedBy = resolvedByObj
      ? ({
          id: resolvedByObj?.getId(),
          email: resolvedByObj.getEmail(),
          fullName: resolvedByObj.getFullName(),
          avatarUrl: resolvedByObj.getAvatarUrl(),
          role: resolvedByObj.getRole(),
          status: resolvedByObj.getStatus(),
          invitedBy: resolvedByObj.getInvitedBy(),
          createdTime: {
            nanos: resolvedByObj.getCreatedTime()?.getNanos(),
            seconds: resolvedByObj.getCreatedTime()?.getSeconds(),
          },
        } as MemberStateMember)
      : undefined;

    resolution = {
      type: 'resolved',
      comment: reportResolutionResolved?.getComment() ?? '',
      resolvedTimeMs: (reportResolutionResolved?.getResolvedTime()?.getSeconds() ?? 0) * 1000,
      resolvedBy,
      complaintCategory: reportResolutionResolved?.getComplaintCategory() ?? '',
      isComplaintJustified: reportResolutionResolved?.getIsComplaintJustified() ?? false,
    };
  }

  return {
    id: report.getId(),
    initiator: {
      profileInfo: report.getInitiator()?.getProfileInfo()?.toObject() ?? defaultProfilePreview(),
      isChatter: report.getInitiator()?.getIsChatter() ?? false,
      isFraud: report.getInitiator()?.getIsFraud() ?? false,
      isScam: report.getInitiator()?.getIsScam() ?? false,
    },
    subject: {
      profileInfo: report.getSubject()?.getProfileInfo()?.toObject() ?? defaultProfilePreview(),
      isChatter: report.getSubject()?.getIsChatter() ?? false,
      isFraud: report.getSubject()?.getIsFraud() ?? false,
      isScam: report.getSubject()?.getIsScam() ?? false,
    },
    reason: report.getReason(),
    details: report.getDetails(),
    source,
    createdTimeMs: (report.getCreatedTime()?.getSeconds() ?? 0) * 1000,
    resolution,
  };
}

export function getMessagesFrom(
  response: ListDialogMessagesResponse,
): ReportAbuseStateDialogMessage[] {
  const messages: ReportAbuseStateDialogMessage[] = [];
  response.getMessagesList().forEach((message: DialogMessage) => {
    messages.push(getMessageFrom(message));
  });

  return messages;
}

export function getMessageFrom(message: DialogMessage): ReportAbuseStateDialogMessage {
  return {
    id: message.getId(),
    fromUserId: message.getFromUserId(),
    toUserId: message.getToUserId(),
    content: getDialogMessageContentFrom(message.getContent()),
    createdDurationMs: (message.getCreatedDuration()?.getSeconds() ?? 0) * 1000,
    createdTimeMs: (message.getCreatedTime()?.getSeconds() ?? 0) * 1000,
    messagePosition: message.getMessagePosition(),
    isNew: message.getIsNew(),
    isSystem: message.getIsSystem(),
    isSuspiciousContent: message.getIsSuspiciousContent(),
    isDeleted: message.getIsDeleted(),
  };
}

export function getDialogFrom(dialog: Dialog): ReportAbuseStateDialog {
  return {
    id: dialog.getId(),
    userProfile: getProfilePreviewFrom(dialog.getUserProfile()),
    subjectProfile: getProfilePreviewFrom(dialog.getSubjectProfile()),
    unreadCount: dialog.getUnreadCount(),
    isDialogActive: dialog.getIsDialogActive(),
    isBlockedBySubject: dialog.getIsBlockedBySubject(),
  };
}
