import {
  Dialog,
  DialogMessage,
} from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/dialogs/v1/dialogs_pb';
import { Profile } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/backoffice/web/report/v1/profile_pb';
import { ProfilePreview } from '@project-gd-x/dating-backoffice-contracts/contracts/gdx/common/v1/profile_pb';

import { Modify } from '@/helpers/type/type';
import { ChatStateDialogMessageContent } from '@/pages/chat/store/chat-state';
import { MemberStateMember } from '@/pages/member/store/member-state';

export type ReportAbuseStateAbuseReport = {
  id: string;
  initiator: Required<Profile.AsObject>;
  subject: Required<Profile.AsObject>;
  reason: ProfileStateAbuseReason;
  details: string;
  source?: SourceProfile | SourceDialog;
  createdTimeMs: number;
  resolution?: ResolutionPending | ResolutionResolved;
};

export enum ProfileStateAbuseReason {
  BLOCK = 1,
  UNCOMFORTABLE = 2,
  ABUSIVE = 3,
  INAPPROPRIATE = 4,
  SPAM = 5,
  STOLEN_PHOTO = 6,
  OTHER = 7,
}

// Describes report status.
// export type Resolution = {
//   pending: ResolutionPending;
//   resolved: ResolutionResolved;
// };

export type ResolutionPending = {
  type: 'pending';
};

export type ResolutionResolved = {
  type: 'resolved';
  resolvedBy?: MemberStateMember;
  comment: string;
  resolvedTimeMs: number;
  isComplaintJustified: boolean;
  complaintCategory: string;
};

// Describes source profile of the abuser.
export type SourceProfile = {
  type: 'profile';
  userId: string;
};

// Describes source dialog from reporter perspective.
export type SourceDialog = {
  type: 'dialog';
  dialogId: string;
  userId: string;
};

export type ReportAbuseStateDialog = Modify<
  Dialog.AsObject,
  {
    userProfile: Required<ProfilePreview.AsObject>;
    subjectProfile: Required<ProfilePreview.AsObject>;
  }
>;

export type ReportAbuseState = {
  reportsList: ReportAbuseStateAbuseReport[];
  messagesAfter: string;
  messages: { [key: string]: ReportAbuseStateDialogMessage[] };
  messagesHeaderDialog?: ReportAbuseStateDialog;
  dialogs: ReportAbuseStateDialog[];
};

export const state: ReportAbuseState = {
  reportsList: [],
  messagesAfter: '',
  messages: {},
  messagesHeaderDialog: undefined,
  dialogs: [],
};

export type ReportAbuseStateDialogMessage = Omit<
  DialogMessage.AsObject,
  'content' | 'createdDuration' | 'createdTime'
> & {
  content?: ChatStateDialogMessageContent;
  createdTimeMs: number;
  createdDurationMs: number;
};
